body, html {
  color: #333;
  scroll-behavior: smooth;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  line-height: 1.6;
  overflow-x: hidden;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.inline-container {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.inline-container a.linkedin-link {
  margin-left: 10px;
}

.linkedin-link img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.linkedin-link {
  color: inherit;
  align-items: center;
  text-decoration: none;
  display: flex;
}

h1, h2, h3, p {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color .3s;
}

a:hover {
  color: #000;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
}

nav.navbar {
  z-index: 1000;
  background-color: #0000;
  width: 100%;
  transition: background-color .3s;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 4px 8px #0000001a;
}

nav.navbar.solid {
  background-color: #000;
}

.navbar .container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.logo img {
  height: 30px;
}

.nav-links {
  display: flex;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  color: #fff;
  padding: 10px;
  font-weight: 500;
  transition: background-color .3s, color .3s;
}

.nav-links a:hover {
  color: #00c5d4;
}

.nav-links a.active {
  color: #00c5d4;
  border-bottom: 2px solid #00c5d4;
  font-weight: 700;
}

.navbar:not(.solid) .nav-links a:hover {
  color: #fff;
  background-color: #000000b3;
  border-radius: 5px;
}

.hero {
  color: #fff;
  text-align: center;
  z-index: 1;
  background-image: url("dust_cloud_neon.eb95c198.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: relative;
}

.hero-content {
  opacity: 0;
  text-align: left;
  border-radius: 10px;
  max-width: 1200px;
  padding: 40px;
  animation: 1s forwards fadeInUp;
  transform: translateY(20px);
}

.hero-content h1 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: Roboto, sans-serif;
  font-size: 4em;
  font-weight: 700;
}

.hero-content p {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-family: Roboto, sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.cta-button {
  color: #fff;
  text-transform: uppercase;
  background-color: #000;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px 30px;
  font-family: Roboto, sans-serif;
  font-size: 1em;
  font-weight: 700;
  text-decoration: none;
  transition: box-shadow .3s, transform .3s, background-color .3s;
  display: inline-block;
  position: relative;
  box-shadow: 0 4px 6px #0000001a;
}

.cta-button:hover {
  color: #fff;
  background-color: #360177;
  transform: translateY(-5px);
  box-shadow: 0 0 0 6px #fffc, 0 6px 10px #00000026;
}

.cta-button:active {
  transform: translateY(0);
  box-shadow: 0 0 0 4px #fffc, 0 4px 6px #0000001a;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section {
  text-align: center;
  background-color: #fabece45;
  padding: 80px 20px;
}

.section h2 {
  color: #333;
  margin-bottom: 40px;
  font-size: 2.5em;
}

.section p, .section ul {
  color: #555;
  margin: 20px 0;
  font-size: 1.1em;
}

.section-content {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
}

.row {
  margin-bottom: 40px;
  display: flex;
}

.column {
  padding: 10px;
}

.left-column {
  flex: 33%;
}

.right-column {
  flex: 2 66%;
}

.card {
  background-color: #f9f9f9;
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 6px #0000001a;
}

.card table {
  border-collapse: collapse;
  width: 100%;
  min-width: 600px;
}

.card table th, .card table td {
  text-align: left;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.card .table-container {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  width: 100%;
  overflow-x: auto;
}

.card i {
  color: #1585ff;
  margin-bottom: 15px;
  font-size: 48px;
}

.card h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.5em;
}

.card p {
  color: #666;
  font-size: 1em;
  line-height: 1.6;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 15px;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  text-align: left;
  padding: 12px;
  font-size: 1em;
}

th {
  background-color: #f1f1f1;
  font-weight: bold;
}

.feature-cards {
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 30px;
  margin-top: 40px;
  display: flex;
}

.feature-card {
  text-align: left;
  align-items: left;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  flex: 30%;
  max-width: 30%;
  padding: 40px;
  transition: transform .3s, box-shadow .3s;
  display: flex;
  box-shadow: 0 4px 6px #0000001a;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px #00000026;
}

.feature-icon {
  object-fit: cover;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  display: block;
  overflow: hidden;
}

.feature-text h3 {
  color: #333;
  margin-bottom: 15px;
  font-family: Roboto, sans-serif;
  font-size: 1.1em;
  font-weight: 700;
}

.feature-text p {
  color: #555;
  margin-top: auto;
  font-family: Inter, sans-serif;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.6;
}

.audience-cards {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  margin-top: 40px;
  display: flex;
}

.audience-card {
  text-align: left;
  align-items: left;
  color: #fff;
  background-color: #780aff;
  border-radius: 8px;
  flex-direction: column;
  flex: 45%;
  max-width: 40%;
  height: auto;
  padding: 40px;
  transition: transform .3s, box-shadow .3s;
  display: flex;
  box-shadow: 0 4px 6px #0000001a;
}

.audience-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px #00000026;
}

.audience-icon {
  object-fit: cover;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  display: block;
  overflow: hidden;
}

.audience-text h3 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: Roboto, sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.audience-text h4 {
  color: #fff;
  margin-bottom: 15px;
  font-family: Roboto, sans-serif;
  font-size: 1.2em;
  font-weight: 500;
}

.audience-text p {
  color: #fff;
  margin-top: auto;
  font-family: Inter, sans-serif;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.6;
}

.contact-info .inline-container {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.contact-info .inline-container p {
  margin: 0 10px 0 0;
}

.contact-info p, .contact-details p {
  color: #555;
  margin: 10px 0;
  font-size: 1em;
}

.social-links {
  justify-content: center;
  margin-top: 20px;
  display: flex;
}

.social-links a {
  margin: 0 10px;
  transition: opacity .3s;
}

.social-links a:hover {
  opacity: .7;
}

.social-links img {
  width: 30px;
  height: 30px;
}

.footer {
  color: #333;
  text-align: center;
  background-color: #f8f8f8;
  padding: 20px 0;
}

.footer a {
  color: #007bff;
  margin: 0 10px;
  font-size: .9em;
  transition: opacity .3s;
}

.footer a:hover {
  opacity: .7;
}

.language-switcher {
  z-index: 2000;
  background: #fff;
  border-radius: 5px;
  align-items: center;
  padding: 5px 10px;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 2px 5px #0003;
}

.language-switcher a {
  color: #333;
  border-radius: 5px;
  align-items: center;
  margin: 0 5px;
  padding: 0 5px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
}

.language-switcher a img {
  width: 24px;
  margin-right: 5px;
}

.language-switcher a.active {
  color: #fff;
  background-color: #00c5d4;
}

.language-switcher a.active img {
  filter: brightness(0) invert();
}

.material-icons {
  color: #1585ff;
  margin-right: 10px;
  font-size: 36px;
}

@media only screen and (width <= 812px) and (orientation: landscape) {
  .section {
    padding: 20px !important;
  }

  .hero {
    background-attachment: scroll !important;
  }

  .hero-content {
    text-align: center !important;
    box-sizing: border-box !important;
    overflow-wrap: break-word !important;
    max-width: 100% !important;
    padding: 20px !important;
  }

  .hero-content h1 {
    font-size: 3em !important;
  }

  .navbar .container {
    flex-direction: row !important;
    justify-content: space-between !important;
    padding: 20px !important;
  }

  .nav-links {
    z-index: 1000 !important;
    background-color: #000 !important;
    border-radius: 5px !important;
    flex-direction: column !important;
    width: 200px !important;
    display: none !important;
    position: absolute !important;
    top: 60px !important;
    right: 0 !important;
  }

  .nav-links.show {
    display: flex !important;
  }

  .nav-links li {
    text-align: right !important;
    margin: 10px 0 !important;
    padding-right: 20px !important;
  }

  .nav-links a {
    color: #fff !important;
    padding: 4px 0 !important;
  }

  .hamburger {
    cursor: pointer !important;
    display: block !important;
  }

  .hamburger .line {
    background-color: #fff !important;
    width: 25px !important;
    height: 3px !important;
    margin: 5px 0 !important;
  }

  .feature-cards, .audience-cards, .cards-row {
    flex-direction: column !important;
    align-items: center !important;
  }

  .feature-card, .audience-card, .card {
    max-width: 100%;
    margin: 0 0 20px !important;
    padding: 20px !important;
  }

  .row {
    flex-direction: column !important;
    align-items: center !important;
    display: flex !important;
  }

  .column {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .section-content {
    padding: 0 !important;
  }

  .contact-info .inline-container {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .contact-info .inline-container p {
    margin: 0 !important;
  }

  .contact-info .linkedin-link, .contact-info .inline-container p:last-child {
    margin-top: 5px !important;
  }
}

@media (width <= 768px) {
  .section {
    padding: 20px !important;
  }

  .hero {
    background-attachment: scroll !important;
  }

  .hero-content {
    text-align: center !important;
    box-sizing: border-box !important;
    overflow-wrap: break-word !important;
    max-width: 100% !important;
    padding: 20px !important;
  }

  .hero-content h1 {
    font-size: 3em !important;
  }

  .navbar .container {
    flex-direction: row !important;
    justify-content: space-between !important;
    padding: 20px !important;
  }

  .nav-links {
    z-index: 1000 !important;
    background-color: #000 !important;
    border-radius: 5px !important;
    flex-direction: column !important;
    width: 200px !important;
    display: none !important;
    position: absolute !important;
    top: 60px !important;
    right: 0 !important;
  }

  .nav-links.show {
    display: flex !important;
  }

  .nav-links li {
    text-align: right !important;
    margin: 10px 0 !important;
    padding-right: 20px !important;
  }

  .nav-links a {
    color: #fff !important;
    padding: 4px 0 !important;
  }

  .hamburger {
    cursor: pointer !important;
    display: block !important;
  }

  .hamburger .line {
    background-color: #fff !important;
    width: 25px !important;
    height: 3px !important;
    margin: 5px 0 !important;
  }

  .feature-cards, .audience-cards, .cards-row {
    flex-direction: column !important;
    align-items: center !important;
  }

  .feature-card, .audience-card, .card {
    max-width: 100%;
    margin: 0 0 20px !important;
    padding: 20px !important;
  }

  .row {
    flex-direction: column !important;
    align-items: center !important;
    display: flex !important;
  }

  .column {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .section-content {
    padding: 0 !important;
  }

  .contact-info .inline-container {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .contact-info .inline-container p {
    margin: 0 !important;
  }

  .contact-info .linkedin-link, .contact-info .inline-container p:last-child {
    margin-top: 5px !important;
  }
}

@media only screen and (device-width >= 390px) and (device-width <= 844px) and (-webkit-device-pixel-ratio: 3) {
  .section {
    padding: 20px !important;
  }

  .hero {
    background-attachment: scroll !important;
  }

  .hero-content {
    text-align: center !important;
    box-sizing: border-box !important;
    overflow-wrap: break-word !important;
    max-width: 100% !important;
    padding: 20px !important;
  }

  .hero-content h1 {
    font-size: 3em !important;
  }

  .navbar .container {
    flex-direction: row !important;
    justify-content: space-between !important;
    padding: 20px !important;
  }

  .nav-links {
    z-index: 1000 !important;
    background-color: #000 !important;
    border-radius: 5px !important;
    flex-direction: column !important;
    width: 200px !important;
    display: none !important;
    position: absolute !important;
    top: 60px !important;
    right: 0 !important;
  }

  .nav-links.show {
    display: flex !important;
  }

  .nav-links li {
    text-align: right !important;
    margin: 10px 0 !important;
    padding-right: 20px !important;
  }

  .nav-links a {
    color: #fff !important;
    padding: 4px 0 !important;
  }

  .hamburger {
    cursor: pointer !important;
    display: block !important;
  }

  .hamburger .line {
    background-color: #fff !important;
    width: 25px !important;
    height: 3px !important;
    margin: 5px 0 !important;
  }

  .feature-cards, .audience-cards, .cards-row {
    flex-direction: column !important;
    align-items: center !important;
  }

  .feature-card, .audience-card, .card {
    max-width: 100%;
    margin: 0 0 20px !important;
    padding: 20px !important;
  }

  .row {
    flex-direction: column !important;
    align-items: center !important;
    display: flex !important;
  }

  .column {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .section-content {
    padding: 0 !important;
  }

  .contact-info .inline-container {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .contact-info .inline-container p {
    margin: 0 !important;
  }

  .contact-info .linkedin-link, .contact-info .inline-container p:last-child {
    margin-top: 5px !important;
  }
}
/*# sourceMappingURL=index.4a6aeb35.css.map */

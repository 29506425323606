/* styles.css */

/* Global Styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    color: #333;
    background-color: #ffffff;
    scroll-behavior: smooth;
    overflow-x: hidden;
    line-height: 1.6;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.inline-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adjust spacing between multiple containers */
}
.inline-container a.linkedin-link {
    margin-left: 10px; /* Add space between the name and LinkedIn link */
}

.linkedin-link img {
    width: 24px; /* Set the width of the image */
    height: 24px; /* Set the height of the image */
    margin-right: 5px; /* Space between the image and the text */
}

.linkedin-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

h1, h2, h3, p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease;
}

a:hover {
    color: #000000;
}

ul {
    list-style: none;
    padding: 0;
}

button {
    cursor: pointer;
    background: none;
    border: none;
    font-family: inherit;
}

nav.navbar {
    background-color: transparent; /* Transparent to show the background image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease;
}

nav.navbar.solid {
    background-color: #000000; /* Solid background color */
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px 0; */
}

.logo img {
    height: 30px;
}

.nav-links {
    display: flex;
}

.nav-links li {
    margin-left: 20px;
}

.nav-links a {
    font-weight: 500;
    transition: color 0.3s ease;
    padding: 10px ; /* Add some padding */
    color: white; /* Default color on transparent nav */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
}

.nav-links a:hover {
    color: #00C5D4;
}

.nav-links a.active {
    color: #00C5D4; /* Active color */
    font-weight: 700; /* Bold text for active link */
    border-bottom: 2px solid #00C5D4; /* Optional underline effect */
}

/* Hover effect for non-solid navbar */
.navbar:not(.solid) .nav-links a:hover {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7); /* Black 70% transparent background */
    color: #ffffff; /* Ensure text color stays white */
}

.hero {
    background-image: url('./images/dust_cloud_neon.webp');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    position: relative;
    z-index: 1;
}

.hero-content {
    max-width: 1200px;
    padding: 40px;
    border-radius: 10px;
    opacity: 0; /* Initial opacity for animation */
    transform: translateY(20px); /* Initial transform for animation */
    animation: fadeInUp 1s forwards; /* Animation applied */
    text-align: left; /* Align text to the left */ 
}

.hero-content h1 {
    font-size: 4em;
    margin-bottom: 20px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif; /* Apply Roboto Bold font */
    font-weight: 700; /* Bold weight */
    text-transform: uppercase; /* Make text all caps */
}

.hero-content p {
    font-size: 1.5em;
    margin-bottom: 30px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif; /* Apply Roboto Bold font */
    font-weight: 700; /* Bold weight */
    text-transform: uppercase; /* Make text all caps */
}

.cta-button {
    display: inline-block;
    margin-top: 20px;
    padding: 15px 30px;
    background-color: #000000; /* Button background color */
    color: #ffffff; /* Text color */
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 1em;
    border-radius: 5px;
    text-transform: uppercase;
    text-decoration: none; /* Remove underline */
    transition: box-shadow 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    position: relative;
    border: none; /* No border initially */
    
}

.cta-button:hover {
    background-color: #360177; /* Darker blue on hover */
    color: #ffffff; /* Text color */
    transform: translateY(-5px); /* Lift effect */
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.8), /* White offset border */
                0 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.cta-button:active {
    transform: translateY(0); /* Reset transform on click */
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.8), /* Reduced offset border on click */
                0 4px 6px rgba(0, 0, 0, 0.1); /* Normal shadow on click */
}


@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.section {
    padding: 80px 20px;
    background-color: #fabece45;
    text-align: center;
}

.section h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
}

.section p, .section ul {
    font-size: 1.1em;
    color: #555;
    margin: 20px 0;
}

.section-content {
    max-width: 1200px; /* Make the content as wide as the navbar */
    padding: 40px;
    padding-bottom: 0;
    padding-top: 0;
    margin: 0 auto; /* Center content horizontally */
    text-align: left; /* Align text to the left */
}

.row {
    display: flex;
    margin-bottom: 40px; /* Spacing between rows */
}

.column {
    padding: 10px;
}

.left-column {
    flex: 1 1 33%; /* One third of the width */
}

.right-column {
    flex: 2 1 66%; /* Two thirds of the width */
}

.card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Make sure the card height is 100% of its parent */
    overflow: hidden; /* Hide any overflowing content */
}

.card table {
    width: 100%;
    min-width: 600px; /* Ensure table does not shrink below a certain width */
    border-collapse: collapse;
}

.card table th, 
.card table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    white-space: nowrap; /* Prevent table cells from wrapping */
}

.card .table-container {
    overflow-x: auto; /* Enable horizontal scrolling if needed */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in width */
}

.card i {
    font-size: 48px;
    margin-bottom: 15px;
    color: #1585FF; /* Icon color */
}

.card h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: #333;
}

.card p {
    font-size: 1em;
    color: #666;
    line-height: 1.6;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
}

table, th, td {
    border: 1px solid #ddd;
}

th, td {
    padding: 12px;
    text-align: left;
    font-size: 1em;
}

th {
    background-color: #f1f1f1;
    font-weight: bold;
}

.feature-cards {
    display: flex;
    justify-content: space-between; /* Space cards evenly */
    flex-wrap: nowrap; /* Prevent wrapping on larger screens */
    gap: 30px; /* Space between cards */
    margin-top: 40px; /* Add some margin to the top of the cards */
}

.feature-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 40px;
    flex: 1 1 30%; /* Ensure three cards fit side by side */
    max-width: 30%; /* Ensure each card only takes up 30% of the width */
    text-align: left; /* Center text within each card */
    display: flex;
    flex-direction: column;
    align-items: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px); /* Subtle lift on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.feature-icon {
    width: 80px; /* Define the size for the circle */
    height: 80px; /* Ensure the height matches the width for a circle */
    border-radius: 50%; /* Create the circle shape */
    overflow: hidden; /* Hide overflow to maintain circle shape */
    margin-bottom: 20px; /* Space between icon and text */
    display: block;
    object-fit: cover; /* Ensure the image covers the entire circle */
}

.feature-text h3 {
    font-family: 'Roboto', sans-serif; /* Use Roboto for titles */
    font-weight: 700; /* Bold weight */
    font-size: 1.1em; /* Larger font size for titles */
    color: #333;
    margin-bottom: 15px; /* Space between title and description */
    /* text-transform: uppercase; */ /* Make titles uppercase */
}

.feature-text p {
    font-family: 'Inter', sans-serif; /* Use Inter for descriptions */
    font-weight: 400; /* Regular weight */
    font-size: 1em; /* Regular font size for text */
    color: #555;
    line-height: 1.6; /* Improve readability with line height */
    margin-top: auto; /* Push the paragraph to the bottom */
}

.audience-cards {
    display: flex;
    justify-content: space-between; /* Space cards evenly */
    gap: 30px; /* Space between cards */
    margin-top: 40px; /* Add some margin to the top of the cards */
    flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

.audience-card {
    background-color: rgba(120, 10, 255, 1); /* Background with transparency */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 40px;
    flex: 1 1 45%; /* Two cards in one row with space between */
    max-width: 40%; /* Ensure two cards fit side by side */
    text-align: left; /* Center text within each card */
    display: flex;
    flex-direction: column;
    align-items: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: auto; /* Adjust height dynamically based on content */
    color: #ffffff; /* Ensure text is white for readability */
    /* justify-content: space-between; */ /* Align text within the card */
}

.audience-card:hover {
    transform: translateY(-5px); /* Subtle lift on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.audience-icon {
    width: 80px; /* Size for icons */
    height: 80px; /* Ensure the height matches the width for a circle */
    border-radius: 50%; /* Create the circle shape */
    overflow: hidden; /* Hide overflow to maintain circle shape */
    margin-bottom: 20px; /* Space between icon and text */
    display: block;
    object-fit: cover; /* Ensure the image covers the entire circle */
}

.audience-text h3 {
    font-family: 'Roboto', sans-serif; /* Use Roboto for titles */
    font-weight: 700; /* Bold weight */
    font-size: 1.5em; /* Larger font size for titles */
    color: #ffffff;
    margin-bottom: 10px; /* Space between title and subtitle */
    text-transform: uppercase; /* Make titles uppercase */
}

.audience-text h4 {
    font-family: 'Roboto', sans-serif; /* Use Roboto for subtitles */
    font-weight: 500; /* Medium weight */
    font-size: 1.2em; /* Font size for subtitles */
    color: #ffffff;
    margin-bottom: 15px; /* Space between subtitle and description */
}

.audience-text p {
    font-family: 'Inter', sans-serif; /* Use Inter for descriptions */
    font-weight: 400; /* Regular weight */
    font-size: 1em; /* Regular font size for text */
    color: #ffffff; /* Text color white for better contrast */
    line-height: 1.6; /* Improve readability with line height */
    margin-top: auto; /* Push the paragraph to the bottom */
}

.contact-info .inline-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.contact-info .inline-container p {
    margin: 0 10px 0 0; /* Add space between name and LinkedIn link */
}

.contact-info p, .contact-details p {
    font-size: 1em;
    margin: 10px 0;
    color: #555;
}

.social-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.social-links a {
    margin: 0 10px;
    transition: opacity 0.3s ease;
}

.social-links a:hover {
    opacity: 0.7;
}

.social-links img {
    width: 30px;
    height: 30px;
}

.footer {
    background-color: #f8f8f8;
    color: #333;
    padding: 20px 0;
    text-align: center;
}

.footer a {
    color: #007BFF;
    margin: 0 10px;
    font-size: 0.9em;
    transition: opacity 0.3s ease;
}

.footer a:hover {
    opacity: 0.7;
}

.language-switcher {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 2000;
    display: flex;
    align-items: center;
}

.language-switcher a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    margin: 0 5px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.language-switcher a img {
    width: 24px; /* Smaller flags */
    margin-right: 5px;
}

.language-switcher a.active {
    background-color: #00C5D4;
    color: #fff;
}

.language-switcher a.active img {
    filter: brightness(0) invert(1);
}

.material-icons {
    font-size: 36px; /* Change size */
    color: #1585FF; /* Change color */
    margin-right: 10px; /* Add spacing */
}

/* @media (max-width: 768px) { */
@media only screen and (max-width: 812px) and (orientation: landscape) {
    .section {
        padding: 20px !important;
    }

    .hero {
        background-attachment: scroll !important; /* Mobile devices often handle fixed background poorly */
    }

    .hero-content {
        padding: 20px !important;
        text-align: center !important; /* Center the text */
        box-sizing: border-box !important; /* Ensure padding is included in width calculations */
        max-width: 100% !important; /* Ensure the content doesn't exceed screen width */
        overflow-wrap: break-word !important; /* Break long words to fit within the container */
    }

    .hero-content h1 {
        font-size: 3em !important;
    }

    .navbar .container {
        flex-direction: row !important;
        justify-content: space-between !important;
        padding: 20px !important;
    }

    .nav-links {
        display: none !important;
        flex-direction: column !important;
        background-color: #000000 !important;
        position: absolute !important;
        top: 60px !important; /* Adjust based on the navbar height */
        right: 0 !important;
        width: 200px !important; /* Width of the dropdown menu */
        border-radius: 5px !important;
        z-index: 1000 !important;
    }

    .nav-links.show {
        display: flex !important;
    }

    .nav-links li {
        margin: 10px 0 !important;
        text-align: right !important; /* Align text to the right */
        padding-right: 20px !important; /* Add padding for spacing */
    }

    .nav-links a {
        color: #ffffff !important; /* White text on dropdown */
        padding: 4px 0 !important;
    }

    .hamburger {
        display: block !important;
        cursor: pointer !important;
    }

    .hamburger .line {
        width: 25px !important;
        height: 3px !important;
        background-color: white !important;
        margin: 5px 0 !important;
    }

    .feature-cards, .audience-cards, .cards-row {
        flex-direction: column !important; /* Stack cards vertically on smaller screens */
        align-items: center !important;
    }

    .feature-card, .audience-card, .card {
        margin: 0 !important;
        max-width: 100%; /* Allow full width on mobile */
        margin-bottom: 20px !important; /* Add space between stacked cards */
        padding: 20px !important;
    }

    .row {
        display: flex !important;
        flex-direction: column !important; /* Stack the columns vertically */
        align-items: center !important;
    }

    .column {
        width: 100% !important; /* Make columns occupy full width */
        margin-bottom: 20px !important; /* Add space between stacked columns */
    }

    .section-content {
        padding: 0px !important;
    }

    .contact-info .inline-container {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .contact-info .inline-container p {
        margin: 0 !important; /* Reset margins for a cleaner stacked appearance */
    }

    .contact-info .linkedin-link {
        margin-top: 5px !important; /* Add space between the name and LinkedIn link */
    }

    .contact-info .inline-container p:last-child {
        margin-top: 5px !important; /* Add space between LinkedIn link and email */
    }

}

@media (max-width: 768px) {
    .section {
        padding: 20px !important;
    }

    .hero {
        background-attachment: scroll !important; /* Mobile devices often handle fixed background poorly */
    }

    .hero-content {
        padding: 20px !important;
        text-align: center !important; /* Center the text */
        box-sizing: border-box !important; /* Ensure padding is included in width calculations */
        max-width: 100% !important; /* Ensure the content doesn't exceed screen width */
        overflow-wrap: break-word !important; /* Break long words to fit within the container */
    }

    .hero-content h1 {
        font-size: 3em !important;
    }

    .navbar .container {
        flex-direction: row !important;
        justify-content: space-between !important;
        padding: 20px !important;
    }

    .nav-links {
        display: none !important;
        flex-direction: column !important;
        background-color: #000000 !important;
        position: absolute !important;
        top: 60px !important; /* Adjust based on the navbar height */
        right: 0 !important;
        width: 200px !important; /* Width of the dropdown menu */
        border-radius: 5px !important;
        z-index: 1000 !important;
    }

    .nav-links.show {
        display: flex !important;
    }

    .nav-links li {
        margin: 10px 0 !important;
        text-align: right !important; /* Align text to the right */
        padding-right: 20px !important; /* Add padding for spacing */
    }

    .nav-links a {
        color: #ffffff !important; /* White text on dropdown */
        padding: 4px 0 !important;
    }

    .hamburger {
        display: block !important;
        cursor: pointer !important;
    }

    .hamburger .line {
        width: 25px !important;
        height: 3px !important;
        background-color: white !important;
        margin: 5px 0 !important;
    }

    .feature-cards, .audience-cards, .cards-row {
        flex-direction: column !important; /* Stack cards vertically on smaller screens */
        align-items: center !important;
    }

    .feature-card, .audience-card, .card {
        margin: 0 !important;
        max-width: 100%; /* Allow full width on mobile */
        margin-bottom: 20px !important; /* Add space between stacked cards */
        padding: 20px !important;
    }

    .row {
        display: flex !important;
        flex-direction: column !important; /* Stack the columns vertically */
        align-items: center !important;
    }

    .column {
        width: 100% !important; /* Make columns occupy full width */
        margin-bottom: 20px !important; /* Add space between stacked columns */
    }

    .section-content {
        padding: 0px !important;
    }

    .contact-info .inline-container {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .contact-info .inline-container p {
        margin: 0 !important; /* Reset margins for a cleaner stacked appearance */
    }

    .contact-info .linkedin-link {
        margin-top: 5px !important; /* Add space between the name and LinkedIn link */
    }

    .contact-info .inline-container p:last-child {
        margin-top: 5px !important; /* Add space between LinkedIn link and email */
    }

}

@media only screen and (min-device-width: 390px) and (max-device-width: 844px) and (-webkit-device-pixel-ratio: 3) {
    /* Styles specifically for iPhone 12 */
    .section {
        padding: 20px !important;
    }

    .hero {
        background-attachment: scroll !important; /* Mobile devices often handle fixed background poorly */
    }

    .hero-content {
        padding: 20px !important;
        text-align: center !important; /* Center the text */
        box-sizing: border-box !important; /* Ensure padding is included in width calculations */
        max-width: 100% !important; /* Ensure the content doesn't exceed screen width */
        overflow-wrap: break-word !important; /* Break long words to fit within the container */
    }

    .hero-content h1 {
        font-size: 3em !important;
    }

    .navbar .container {
        flex-direction: row !important;
        justify-content: space-between !important;
        padding: 20px !important;
    }

    .nav-links {
        display: none !important;
        flex-direction: column !important;
        background-color: #000000 !important;
        position: absolute !important;
        top: 60px !important; /* Adjust based on the navbar height */
        right: 0 !important;
        width: 200px !important; /* Width of the dropdown menu */
        border-radius: 5px !important;
        z-index: 1000 !important;
    }

    .nav-links.show {
        display: flex !important;
    }

    .nav-links li {
        margin: 10px 0 !important;
        text-align: right !important; /* Align text to the right */
        padding-right: 20px !important; /* Add padding for spacing */
    }

    .nav-links a {
        color: #ffffff !important; /* White text on dropdown */
        padding: 4px 0 !important;
    }

    .hamburger {
        display: block !important;
        cursor: pointer !important;
    }

    .hamburger .line {
        width: 25px !important;
        height: 3px !important;
        background-color: white !important;
        margin: 5px 0 !important;
    }

    .feature-cards, .audience-cards, .cards-row {
        flex-direction: column !important; /* Stack cards vertically on smaller screens */
        align-items: center !important;
    }

    .feature-card, .audience-card, .card {
        margin: 0 !important;
        max-width: 100%; /* Allow full width on mobile */
        margin-bottom: 20px !important; /* Add space between stacked cards */
        padding: 20px !important;
    }

    .row {
        display: flex !important;
        flex-direction: column !important; /* Stack the columns vertically */
        align-items: center !important;
    }

    .column {
        width: 100% !important; /* Make columns occupy full width */
        margin-bottom: 20px !important; /* Add space between stacked columns */
    }

    .section-content {
        padding: 0px !important;
    }

    .contact-info .inline-container {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .contact-info .inline-container p {
        margin: 0 !important; /* Reset margins for a cleaner stacked appearance */
    }

    .contact-info .linkedin-link {
        margin-top: 5px !important; /* Add space between the name and LinkedIn link */
    }

    .contact-info .inline-container p:last-child {
        margin-top: 5px !important; /* Add space between LinkedIn link and email */
    }
}
